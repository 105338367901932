import { createTasks as createAppTasks } from '../app/tasks';

export const createTasks = dispatch => {
  const init = async () => {};
  const { handle404 } = createAppTasks(dispatch);

  return {
    init,
    handle404,
  };
};
