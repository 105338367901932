import { page, withSEO } from 'utils/views';
import Template from './template';
import { createTasks } from './tasks';
import { Landing as withLayout } from 'layouts';

export default withSEO({
  title: 'GrowFlow Live',
})(
  page({
    Template,
    withLayout,
    createTasks,
  })
);
