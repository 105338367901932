import React, { Fragment } from 'react';

import { Button } from 'antd';
import FourOFour from 'components/FourOFour';
import { noOp } from 'utils/core/funcy';

const NotFoundTemplate = ({ handle404 = noOp } = {}) => (
  <FourOFour
    width={560}
    height={560}
    Message={
      <Fragment>
        <p className="regular">Sorry, we can’t find that page</p>
        <Button type="link" onClick={handle404}>
          Go Home
        </Button>
      </Fragment>
    }
  />
);

export default NotFoundTemplate;
