import Lottie from 'react-lottie';
import React from 'react';
import { fourOFourContainer } from './styles';
import loaderData from './fourOFour.json';

const FourOFour = ({ width = 375, height = 375, Message = null }) => (
  <div className={fourOFourContainer}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: loaderData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      width={width}
      height={height}
    />
    {Message ? <div className="message">{Message}</div> : null}
  </div>
);

export default FourOFour;
